var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.contract != null
    ? _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mt-4",
                      attrs: { header: "Contract - " + _vm.contract.Name }
                    },
                    [
                      _c("table", { staticClass: "table table-bordered" }, [
                        _c("tr", [
                          _c("th", [_vm._v("Naam")]),
                          _c("td", [_vm._v(_vm._s(_vm.contract.Name))]),
                          _c("th", [_vm._v("Status")]),
                          _c("td", [_vm._v(_vm._s(_vm.contract.Status))])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Bedrijf")]),
                          _c("td", [_vm._v(_vm._s(_vm.contract.Company))]),
                          _c("th", [_vm._v("Is Label")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm._f("yesno")(_vm.contract.IsLabel))
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Adres")]),
                          _c("td", [_vm._v(_vm._s(_vm.contract.Address))]),
                          _c("th", [_vm._v("Is Portal")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm._f("yesno")(_vm.contract.IsPortal))
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Postcode")]),
                          _c("td", [_vm._v(_vm._s(_vm.contract.Postcode))]),
                          _c("th", [_vm._v("Bank rekeningnummer")]),
                          _c("td", [_vm._v(_vm._s(_vm.contract.BankNumber))])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Plaats")]),
                          _c("td", [_vm._v(_vm._s(_vm.contract.City))]),
                          _c("th", [_vm._v("Bank naam")]),
                          _c("td", [_vm._v(_vm._s(_vm.contract.BankName))])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Land")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.$root.countries[_vm.contract.Country])
                            )
                          ]),
                          _c("th", [_vm._v("Bank Plaats")]),
                          _c("td", [_vm._v(_vm._s(_vm.contract.BankCity))])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Tel")]),
                          _c("td", [_vm._v(_vm._s(_vm.contract.Tel))]),
                          _c("th", [_vm._v("Bank Land")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.$root.countries[_vm.contract.BankCountry]
                              )
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Fax")]),
                          _c("td", [_vm._v(_vm._s(_vm.contract.Fax))]),
                          _c("th", [_vm._v("IBAN")]),
                          _c("td", [_vm._v(_vm._s(_vm.contract.Iban))])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Website")]),
                          _c("td", [_vm._v(_vm._s(_vm.contract.Website))]),
                          _c("th", [_vm._v("BIC")]),
                          _c("td", [_vm._v(_vm._s(_vm.contract.Bic))])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Mailreport")]),
                          _c("td", [_vm._v(_vm._s(_vm.contract.Mailreport))]),
                          _c("th", [_vm._v("BTW Nr.")]),
                          _c("td", [_vm._v(_vm._s(_vm.contract.Btw))])
                        ]),
                        _c("tr"),
                        _c("tr", [
                          _c("th", [_vm._v("Restrict Portals")]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.contract.RestrictPortals))
                          ]),
                          _c("th", [_vm._v("KvK")]),
                          _c("td", [_vm._v(_vm._s(_vm.contract.Kvk))])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("ID")]),
                          _c("td", [_vm._v(_vm._s(_vm.contract.ContractID))]),
                          _c("th"),
                          _c("td")
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Aangemaakt")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("datetimeFormat")(_vm.contract.Created)
                              )
                            )
                          ]),
                          _c("th", [_vm._v("Aangepast")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("datetimeFormat")(_vm.contract.Modified)
                              )
                            )
                          ])
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "b-card",
                    { staticClass: "mt-4", attrs: { header: "Gebruikers" } },
                    [
                      _c(
                        "i",
                        {
                          staticStyle: {
                            "font-weight": "bold",
                            "font-size": "18px",
                            color: "#bf0000"
                          }
                        },
                        [_vm._v("»")]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            to: {
                              name: "user-detail-edit",
                              params: {
                                id: "new",
                                ContractID: _vm.contract.ContractID
                              }
                            }
                          }
                        },
                        [_vm._v("Nieuwe user aanmaken...")]
                      ),
                      _c("user-list", {
                        staticClass: "mt-4",
                        attrs: {
                          filter: { ContractID: _vm.contract.ContractID }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    { staticClass: "mt-4", attrs: { header: "Sub Labels" } },
                    [
                      _c(
                        "i",
                        {
                          staticStyle: {
                            "font-weight": "bold",
                            "font-size": "18px",
                            color: "#bf0000"
                          }
                        },
                        [_vm._v("»")]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            to: {
                              name: "label-detail-edit",
                              params: {
                                id: "new",
                                ContractID: _vm.contract.ContractID,
                                ParentLabelID: null
                              }
                            }
                          }
                        },
                        [_vm._v("Nieuw label aanmaken...")]
                      ),
                      _c("label-list", {
                        staticClass: "mt-4",
                        attrs: {
                          filter: { ContractID: _vm.contract.ContractID }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }