<template>
  <rest-table
      endpoint="users"
      :fields="fields"
      :filter="filter">

    <template v-slot:cell(Username)="data">
      <router-link :to="{ name: 'user-detail', params: { id: data.item.UserID } }">
        <strong>{{ data.item.Username }}</strong>
      </router-link>
    </template>

    <template v-slot:cell(Contract.Name)="data">
      <router-link :to="{ name: 'contract-detail', params: { id: data.item.ContractID } }">
        <strong>{{ data.item.Contract.Name }}</strong>
      </router-link>
    </template>

    <template v-slot:cell(LastLogin)="data">
      {{ data.item.LastLogin|dateFormat }}
    </template>

    <template v-slot:cell(actions)="data">
      <router-link :to="{ name: 'user-detail-edit', params: { id: data.item.UserID } }">
        <b-icon-pencil-square></b-icon-pencil-square>
      </router-link>

      <router-link :to="{ name: 'impersonate', params: { id: data.item.UserID } }" v-b-tooltip.hover title="impersonate">
        <b-icon-person-badge class="ml-2" />
      </router-link>
    </template>
  </rest-table>
</template>

<script>
  import RestTable from '@/components/RestTable.vue'

  export default {
    name: 'AlbumTrackList',
    components: {
      RestTable,
    },
    props: {
      filter: Object
    },
    data () {
      return {
        fields: [
          {
            key: 'idx',
            label: '#'
          },
          {
            key: 'ContactName',
            label: 'Naam',
            sortable: true,
          },
          {
            key: 'Username',
            label: 'Gebruikersnaam',
            sortable: true,
          },
          {
            key: 'Email',
            label: 'E-mail',
            sortable: true,
          },
          {
            key: 'Contract.Name',
            label: 'Contract naam',
            sortable: true,
          },
          {
            key: 'Status',
            label: 'Status',
            sortable: true,
          },
          {
            key: 'LastLogin',
            label: 'Laatste login',
            sortable: true,
          },
          {
            key: 'actions',
            label: 'Acties',
            class: 'link-icons',
          },
        ],
      }
    }
  }
</script>