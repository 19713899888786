<template>
  <b-container fluid v-if="contract != null">
    <b-row>
      <b-col>
        <b-card :header="'Contract - ' + contract.Name" class="mt-4">

          <table class="table table-bordered">
            <tr>
              <th>Naam</th><td>{{ contract.Name }}</td>
              <th>Status</th><td>{{ contract.Status }}</td>
            </tr>
            <tr>
              <th>Bedrijf</th><td>{{ contract.Company }}</td>
              <th>Is Label</th><td>{{ contract.IsLabel|yesno }}</td>
            </tr>
            <tr>
              <th>Adres</th><td>{{ contract.Address }}</td>
              <th>Is Portal</th><td>{{ contract.IsPortal|yesno }}</td>
            </tr>
            <tr>
              <th>Postcode</th><td>{{ contract.Postcode }}</td>
              <th>Bank rekeningnummer</th><td>{{ contract.BankNumber }}</td>
            </tr>
            <tr>
              <th>Plaats</th><td>{{ contract.City }}</td>
              <th>Bank naam</th><td>{{ contract.BankName }}</td>
            </tr>
            <tr>
              <th>Land</th><td>{{ $root.countries[contract.Country] }}</td>
              <th>Bank Plaats</th><td>{{ contract.BankCity }}</td>
            </tr>
            <tr>
              <th>Tel</th><td>{{ contract.Tel }}</td>
              <th>Bank Land</th><td>{{ $root.countries[contract.BankCountry] }}</td>
            </tr>
            <tr>
              <th>Fax</th><td>{{ contract.Fax }}</td>
              <th>IBAN</th><td>{{ contract.Iban }}</td>
            </tr>
            <tr>
              <th>Website</th><td>{{ contract.Website }}</td>
              <th>BIC</th><td>{{ contract.Bic }}</td>
            </tr>
            <tr>
              <th>Mailreport</th><td>{{ contract.Mailreport }}</td>
              <th>BTW Nr.</th><td>{{ contract.Btw }}</td>
            </tr>
            <tr>
            <tr>
              <th>Restrict Portals</th><td>{{ contract.RestrictPortals }}</td>
              <th>KvK</th><td>{{ contract.Kvk }}</td>
            </tr>
            <tr>
              <th>ID</th><td>{{ contract.ContractID }}</td>
              <th></th><td></td>
            </tr>
            <tr>
              <th>Aangemaakt</th><td>{{ contract.Created|datetimeFormat }}</td>
              <th>Aangepast</th><td>{{ contract.Modified|datetimeFormat }}</td>
            </tr>
          </table>
        </b-card>

        <b-card header="Gebruikers" class="mt-4">
          <i style="font-weight:bold; font-size:18px ;color:#bf0000">»</i>
          <router-link :to="{ name: 'user-detail-edit', params: { id: 'new', ContractID: contract.ContractID } }" class="ml-2">Nieuwe user aanmaken...</router-link>

          <user-list class="mt-4" :filter="{ ContractID: contract.ContractID }" />
        </b-card>

        <b-card header="Sub Labels" class="mt-4">
          <i style="font-weight:bold; font-size:18px ;color:#bf0000">»</i>
          <router-link :to="{ name: 'label-detail-edit', params: { id: 'new', ContractID: contract.ContractID, ParentLabelID: null } }" class="ml-2">Nieuw label aanmaken...</router-link>

          <label-list class="mt-4" :filter="{ ContractID: contract.ContractID }" />
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import UserList from '@/components/UserList.vue'
  import LabelList from '@/components/LabelList.vue'

  export default {
    name: "ContractDetail",
    components: {
      UserList,
      LabelList,
    },
    data() {
      return {
      };
    },
    props: {
      contract: Object,
    },
    watch: {
    },
    mounted() {
      this.$root.$data.crumbs = [
        { to: { name: "contract-search" }, text: "Contracten" },
        { text: this.contract.Name },
      ];
    }
  };
</script>

<style lang="scss" scoped>

</style>