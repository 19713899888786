var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("rest-table", {
    attrs: { endpoint: "users", fields: _vm.fields, filter: _vm.filter },
    scopedSlots: _vm._u([
      {
        key: "cell(Username)",
        fn: function(data) {
          return [
            _c(
              "router-link",
              {
                attrs: {
                  to: { name: "user-detail", params: { id: data.item.UserID } }
                }
              },
              [_c("strong", [_vm._v(_vm._s(data.item.Username))])]
            )
          ]
        }
      },
      {
        key: "cell(Contract.Name)",
        fn: function(data) {
          return [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "contract-detail",
                    params: { id: data.item.ContractID }
                  }
                }
              },
              [_c("strong", [_vm._v(_vm._s(data.item.Contract.Name))])]
            )
          ]
        }
      },
      {
        key: "cell(LastLogin)",
        fn: function(data) {
          return [
            _vm._v(
              " " + _vm._s(_vm._f("dateFormat")(data.item.LastLogin)) + " "
            )
          ]
        }
      },
      {
        key: "cell(actions)",
        fn: function(data) {
          return [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "user-detail-edit",
                    params: { id: data.item.UserID }
                  }
                }
              },
              [_c("b-icon-pencil-square")],
              1
            ),
            _c(
              "router-link",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true }
                  }
                ],
                attrs: {
                  to: { name: "impersonate", params: { id: data.item.UserID } },
                  title: "impersonate"
                }
              },
              [_c("b-icon-person-badge", { staticClass: "ml-2" })],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }